import '../css/hero-block.css';

function HeroBlock() {
  return (
    <div className="hero-block">
      <div className='background-image'>
        <div className="text-wrapper">
          <div className="top-row">
            <div className="logo">
              <div>Santa Cruz Residential</div>
              <div>Landscaping & Construction</div>
            </div>
            <div className="right">
              <div>Services</div>
              <div>About</div>
              <div>Contact</div>
              <div>(831) 555-5555</div>
            </div>
          </div>
          <div className="small-text">Serving Santa Cruz County since 1985</div>
          <div className="large-text">Let us help you transform & enhance your living space.</div>
          <div className="yellow-button">Get a quote on your next project →</div>
        </div>
      </div>
    </div>
  );
}

export default HeroBlock;