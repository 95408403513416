import '../css/contact-us-block.css';
import { useState } from 'react';
import check from '../images/check.svg';
import Web3FormActions from './Web3FormActions';
const WEB3FORM_PUBLIC_ACCESS_KEY = "fe5fb786-7529-47a4-ac94-8847105e7fd5"

function ContactUsBlock() {
  const [step, setStep] = useState(1);
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  //
  const [hauling, setHauling] = useState(false);
  const [walls, setWalls] = useState(false);
  const [fencing, setFencing] = useState(false);
  const [concrete, setConcrete] = useState(false);
  const [heater, setHeater] = useState(false);
  const [digging, setDigging] = useState(false);
  const [lines, setLines] = useState(false);
  const [driveway, setDriveway] = useState(false);
  const [other, setOther] = useState(false);
  //
  const [instructions, setInstructions] = useState('');

  const submitForm = () => {
    Web3FormActions.submit({
      access_key: WEB3FORM_PUBLIC_ACCESS_KEY,
      subject: `Inquiry from ${first_name || "<No first name>"} ${last_name || "<No last name>"}`,
      first_name: first_name,
      last_name: last_name,
      address: address,
      city: city,
      phone: phone,
      email: email,
      hauling: hauling,
      walls: walls,
      fencing: fencing,
      concrete: concrete,
      heater: heater,
      digging: digging,
      lines: lines,
      driveway: driveway,
      other: other,
      instructions: instructions
    })


    setStep(2);
  }


  return (
    <div className="contact-us-block">
      <div className="paragraph">
        <div className="small-text">FREE ESTIMATE</div>
        <div className="title">Contact Us Today</div>
        <div className="description">Fill in the information and we’ll get back to you right away for a free quote or estimate. You can also call us at (831) 291-5032 from 7:00am to 3:00pm Pacific Time.</div>
      </div>
      { step === 1 && 
        <div className='contact-form'>
          <div className="left">
            <div className='label'>CONTACT INFORMATION</div>
            <input type="text" value={first_name} placeholder='First Name' onChange={e => setFirstName(e.target.value)} />
            <input type="text" value={last_name} placeholder='Last Name' onChange={e => setLastName(e.target.value)} />
            <input type="text" value={address} placeholder='Address' onChange={e => setAddress(e.target.value)} />
            <input type="text" value={city} placeholder='City' onChange={e => setCity(e.target.value)} />
            <input type="text" value={phone} placeholder='Phone Number' onChange={e => setPhone(e.target.value)} />
            <input type="text" value={email} placeholder='Email Address' onChange={e => setEmail(e.target.value)} />
          </div>
          <div className='right'>
            <div className="checkbox-form">
              <div className='label'>WHAT SERVICES ARE YOU INTERESTED IN?</div>
              <div className="checkbox-group">
                <input type="checkbox" value={hauling} onChange={e => setHauling(e.target.value)} />
                <span>Hauling & Backyard Cleanup</span>
              </div>
              <div className="checkbox-group">
                <input type="checkbox" value={walls} onChange={e => setWalls(e.target.value)} />
                <span>Retaining Walls</span>
              </div>
              <div className="checkbox-group">
                <input type="checkbox" value={fencing} onChange={e => setFencing(e.target.value)} />
                <span>Fencing</span>
              </div>
              <div className="checkbox-group">
                <input type="checkbox" value={concrete} onChange={e => setConcrete(e.target.value)} />
                <span>Concrete & Foundations</span>
              </div>
              <div className="checkbox-group">
                <input type="checkbox" value={heater} onChange={e => setHeater(e.target.value)} />
                <span>Electric Water Heaters</span>
              </div>
              <div className="checkbox-group">
                <input type="checkbox" value={digging} onChange={e => setDigging(e.target.value)} />
                <span>Ditch & Trench Digging</span>
              </div>
              <div className="checkbox-group">
                <input type="checkbox" value={lines} onChange={e => setLines(e.target.value)} />
                <span>Running Utility Lines</span>
              </div>
              <div className="checkbox-group">
                <input type="checkbox" value={driveway} onChange={e => setDriveway(e.target.value)} />
                <span>Building Driveways / Roads</span>
              </div>
              <div className="checkbox-group">
                <input type="checkbox" value={other} onChange={e => setOther(e.target.value)} />
                <span>Other</span>
              </div>
            </div>
            <div>
              <div className='label'>ADDITIONAL CONTEXT OR INSTRUCTIONS</div>
              <textarea rows={4} value={instructions} placeholder='Provide any additional instructions' onChange={e => setInstructions(e.target.value)} />
              <div className="yellow-button" onClick={submitForm}><span>SUBMIT</span><span>→</span></div>
            </div>
          </div>
          
        </div>
      }
      
      
      
      { step === 2 &&
        <div className="submitted">
          <img src={check} alt='' />
          <div>YOUR INQUIRY HAS BEEN SUBMITTED</div>
        </div>
      }
      
    </div>
  );
}

export default ContactUsBlock;