import HeroBlock from './HeroBlock';
import ServicesBlock from './ServicesBlock';
import AboutUsBlock from './AboutUsBlock';
import ContactUsBlock from './ContactUsBlock';
import FooterBlock from './FooterBlock';

function App() {
  return (
    <div>
      <HeroBlock />
      <ServicesBlock />
      <AboutUsBlock />
      <ContactUsBlock />
      <FooterBlock />
    </div>
  );
}

export default App;
