import '../css/about-us-block.css';
import bobcat from '../images/bobcat2.png';

function AboutUsBlock() {
  return (
    <div className="about-us-block">
      <div className='wrapper'>
        <img src={bobcat} />
        <div>
          <div className="title">About Us</div>
          <div className="description">Santa Cruz Residential Landscaping & Construction has been serving Santa Cruz, Monterey, and San Juanita Counties for over 30 years. We are licensed general contractors specializing in outdoor & electric services.</div>
        </div>
      </div>
    </div>
  );
}

export default AboutUsBlock;