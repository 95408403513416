'use strict';
import superagent from 'superagent';

const Web3FormActions = {
  submit: function (params) {
    return new Promise(function (resolve, reject) {
      superagent.post('https://api.web3forms.com/submit')
        .send(params)
        .end((err, res) => {
          if (err) {
            resolve(err)
            return
          }
          resolve(res.body);
        })
    })
  }
};

export default Web3FormActions;