import '../css/footer-block.css';

function FooterBlock() {
  return (
    <div className="footer-block">
      <div className="copyright">© {new Date().getFullYear()} Santa Cruz Residential Landscaping & Construction. All rights reserved</div>
      <div className="logo">
        <div>Santa Cruz Residential</div>
        <div>Landscaping & Construction</div>
      </div>
    </div>
  );
}

export default FooterBlock;