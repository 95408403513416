import '../css/services-block.css';
import mixer from '../images/mixer.svg';
import brickwall from '../images/brickwall.svg';
import sand from '../images/sand.svg';
import road from '../images/road.svg';
import fence from '../images/fence.svg';
import excavator from '../images/excavator.svg';
import valve from '../images/valve.svg';
import cement from '../images/cement2.jpeg';
import digging from '../images/digging2.jpeg';
import excavator2 from '../images/excavator2.jpeg';
import gravel from '../images/gravel.jpeg';
import loader from '../images/loader.jpeg';
import trench from '../images/trench.jpeg';

function ServicesBlock() {
  return (
    <div className="services-block">
      <div className="flex-wrapper">
        <div className="title">Our Services Include</div>
        <div className="list">
          
          <div className="service-block">
            <div className="image"><img src={mixer} alt='' /></div>
            <div>Concrete & Foundations</div>
          </div>
          <div className="service-block">
            <div className="image"><img src={brickwall} alt='' /></div>
            <div>Retaining Walls</div>
          </div>
          <div className="service-block">
            <div className="image"><img src={sand} alt='' /></div>
            <div>Hauling & Backyard Cleanup</div>
          </div>
          <div className="service-block">
            <div className="image"><img src={road} alt='' /></div>
            <div>Building Driveways & Roads</div>
          </div>
          <div className="service-block">
            <div className="image"><img src={fence} alt='' /></div>
            <div>Fencing</div>
          </div>
          <div className="service-block">
            <div className="image"><img src={excavator} alt='' /></div>
            <div>Ditch & Trench Digging</div>
          </div>
          <div className="service-block">
            <div className="image"><img src={valve} alt='' /></div>
            <div>Running Utility Lines</div>
          </div>
        </div>
        <div className="images">
          <div className="row">
            <img src={cement} alt='' />
            <img src={digging} alt='' />
            <img src={excavator2} alt='' />
          </div>
          <div className="row">
            <img src={gravel} alt='' />
            <img src={loader} alt='' />
            <img src={trench} alt='' />
          </div>
        </div>
      </div>
      
    </div>
  );
}

export default ServicesBlock;